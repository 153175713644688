import insta from "../assets/new-Instagram-logo-png-full-colour-glyph.png";
import React from "react";


export default function Footer(){
    return(
    <div className='py-20 bg-black text-gray-400 flex items-center' id='contatti'>
        <div className="w-11/12 lg:w-1/2 mx-auto grid grid-cols-1 md:grid-cols-3">
            <div className='flex flex-col'>
                <p className='text-2xl py-9 text-gray-50'>Live in Colour</p>
                <p className='py-2'>Home</p>
                <p>Chi Siamo</p>
                <p className='py-2'>Servizi</p>
                <p>Calcola Preventivo</p>
                <p className='py-2'> Contatti</p>
            </div>
            <div className='flex flex-col'>
                <p className='text-2xl py-9 text-gray-50'>Resta in contatto</p>
                <p className='py-2'>
                    info@liveincolors.it
                </p>
                <br/>
                <div className='flex flex-wrap'>
                    <a href="https://www.instagram.com/liveincolors.milano/?hl=en" rel="noopener noreferrer" target="_blank">
                        <img src={insta} alt={'ig'} className='w-10 rounded-xl'/>
                    </a>
                </div>

            </div>
            <div className='flex flex-col'>
                <p className='text-2xl py-9 text-gray-50'>Iscriviti alla Newsletter</p>
                <p className='py-2'>
                    Sconto 10% sul primo lavoro. Resta informato sulle ultime novità, offerte speciali e tutto ciò che è nuovo e degno di nota.
                </p>
            </div>
        </div>
    </div>
    )
}
